._4HuoIq_navbar {
  backdrop-filter: blur(25px);
  background: linear-gradient(173deg, #ffffff4d 0%, #ffffff1a 100%);
  border-radius: 16px;
}

._4HuoIq_menu-item {
  letter-spacing: .025rem;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 600;
}

._3TpQ-q_subText, ._3TpQ-q_subHeading, ._3TpQ-q_tinyText, ._3TpQ-q_captionText {
  font-family: Inter, sans-serif;
  font-style: normal;
}

._3TpQ-q_subText {
  font-weight: 400;
}

._3TpQ-q_subHeading {
  font-weight: 300;
}

._3TpQ-q_captionText {
  letter-spacing: 2px;
  font-size: 14px;
  font-weight: 600;
}

._3TpQ-q_captionText2 {
  letter-spacing: 1.4px;
  text-transform: uppercase;
  font-family: Encode Sans, sans-serif;
  font-size: 13px;
  font-weight: 500;
  line-height: 22px;
}

._3TpQ-q_tinyText {
  font-size: 14px;
  font-weight: 400;
}

.-_VRTG_button {
  -webkit-backdrop-filter: blur(4px);
  background: #ffffff40;
  border: 4px solid #ffffff2e;
  border-radius: 10px;
  padding: 1rem 2rem;
  box-shadow: 0 8px 32px #1f26875e;
}

.mSlqzq_hero-container {
  position: relative;
}

.mSlqzq_hero-container:before {
  content: "";
  z-index: -1;
  opacity: .3;
  background-image: url("Grid-Solid.42461803.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: absolute;
  inset: 0;
}

.mSlqzq_hero-sub-heading {
  color: #cbcbcb;
}

.mSlqzq_focused-text {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(-200deg, #ff823f 5% 100%);
  -webkit-background-clip: text;
}

@media (width >= 768px) {
  .mSlqzq_hero-container:before {
    opacity: .15;
  }
}

.f1ReOW_wrapper {
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.f1ReOW_card {
  background: linear-gradient(150deg, #151515 0%, #09090a 51.56%, #151515 100%);
  border: 1px solid;
  border-image: linear-gradient(145deg, #fff 30%, #09090a 51.56%, #ededed 100%) 1;
  margin: 2rem;
  padding: 2rem;
}

.f1ReOW_img {
  margin-right: 1rem;
}

.f1ReOW_specialText {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #ff823f 0%, #ff823f);
  -webkit-background-clip: text;
}

.f1ReOW_contentContainer {
  justify-content: space-between;
  padding: 2rem 5rem;
  display: flex;
}

.f1ReOW_topPart {
  justify-content: start;
  display: flex;
}

.f1ReOW_subText2 {
  color: #dbdbdb;
}

.f1ReOW_imageContainer {
  justify-content: center;
  display: flex;
}

.SYjplq_wrapper {
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.SYjplq_card {
  background: linear-gradient(150deg, #171717 0% 100%);
  border: 1px solid;
  border-image: linear-gradient(145deg, #b3f200 30%, #ff823f 51.56%, #b3f200 100%) 1;
  padding: 2rem;
}

.SYjplq_specialText {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #d3d3d3 0%, #7dff16);
  -webkit-background-clip: text;
}

.SYjplq_subText1 {
  color: #f2f2f2;
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 24px;
}

.SYjplq_container {
  justify-content: space-between;
  padding: 1rem 5rem;
  display: flex;
}

.SYjplq_topPart {
  justify-content: start;
  display: flex;
}

.SYjplq_heading1 {
  color: #fff;
  text-align: center;
  margin: 24px 40px;
  font-family: Manrope, sans-serif;
  font-size: 82px;
  font-weight: 600;
  line-height: 114%;
}

.SYjplq_subText2 {
  color: #ddd;
  text-align: center;
  margin: 40px;
  font-family: Inter, sans-serif;
  font-size: 16px;
}

.SYjplq_imageContainer {
  justify-content: center;
  display: flex;
}

.PlmmNG_grow-card {
  background: linear-gradient(150deg, #171717 0% 100%);
  border-width: 4px 0 0;
  border-image: linear-gradient(83deg, #b3f200 0%, #ff823f 100%) 1;
  padding: 30px;
}

.PlmmNG_grow-card:nth-child(2n) {
  border-image: linear-gradient(83deg, #ff823f 0%, #b3f200 100%) 1;
}

.PlmmNG_card-icon {
  background: #f2f2f2;
  width: fit-content;
}

.cn1sjG_specialText {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #ff823f 0%, #ff823f);
  -webkit-background-clip: text;
}

.cn1sjG_subHeading {
  color: #cbcbcb;
}

@media (width >= 768px) {
  .cn1sjG_main-container {
    background: url("Graph.5e1ddd6b.svg") 0 110% / 38% no-repeat;
  }
}

._3QQd9G_textContainer {
  color: #fff;
}

._3QQd9G_subText2 {
  color: #cbcbcb;
}

._3QQd9G_mainContainer {
  background-image: url("3d.95dd854e.png");
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 75%;
}

._3QQd9G_imageContainer {
  margin: 50px 100px;
}

._3QQd9G_specialText {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #ff823f 0%, #ff823f);
  -webkit-background-clip: text;
}

._3QQd9G_textAndImage {
  align-items: flex-start;
  display: flex;
}

@media (width >= 660px) {
  ._3QQd9G_mainContainer {
    background-size: 45%;
  }
}

._5WoZ-a_textContainer {
  color: #fff;
}

._5WoZ-a_subText2 {
  color: #ddd;
}

._5WoZ-a_mainContainer {
  background-position: 0;
  background-repeat: no-repeat;
  background-size: contain;
}

._5WoZ-a_specialText {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #ff823f 0%, #ff823f);
  -webkit-background-clip: text;
}

.H1TicG_wrapper {
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.H1TicG_specialText {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #ff823f 0%, #ff823f);
  -webkit-background-clip: text;
}

.H1TicG_textContainer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.H1TicG_heading1 {
  color: #fff;
  text-align: center;
  margin: 14px 40px;
  font-family: Manrope, sans-serif;
  font-size: 82px;
  font-weight: 600;
  line-height: 114%;
}

.H1TicG_subText2 {
  color: #ddd;
}

.H1TicG_subHeading {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.H1TicG_subText3 {
  color: #ddd;
  text-align: center;
  max-width: 40%;
  margin: 0 40px;
  font-family: Inter, sans-serif;
  font-size: 16px;
}

.H1TicG_heading2 {
  color: #fff;
  font-family: Manrope, sans-serif;
  font-size: 38px;
  font-weight: 600;
}

.d8hEUW_wrapper {
  color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.d8hEUW_card {
  background: linear-gradient(150deg, #171717 0% 100%);
  border: 1px solid;
  border-image: linear-gradient(145deg, #b3f200 30%, #ff823f 51.56%, #b3f200 100%) 1;
  max-width: 635px;
}

.d8hEUW_specialText {
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(90deg, #5900ff 0%, #7dff16);
  -webkit-background-clip: text;
}

.d8hEUW_subText1 {
  color: #f2f2f2;
  font-family: Inter, sans-serif;
}

.d8hEUW_bottomPart h3 {
  font-family: Manrope, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.d8hEUW_container {
  flex-wrap: wrap;
  justify-content: space-between;
  display: flex;
}

.d8hEUW_topPart {
  justify-content: start;
  display: flex;
}

.d8hEUW_heading1 {
  color: #fff;
  text-align: center;
  margin: 24px 40px;
  font-family: Manrope, sans-serif;
  font-size: 82px;
  font-weight: 600;
  line-height: 114%;
}

.d8hEUW_subText2 {
  color: #ddd;
  text-align: center;
  margin: 40px;
  font-family: Inter, sans-serif;
  font-size: 16px;
}

.d8hEUW_imageContainer {
  justify-content: center;
  display: flex;
}

.ScEwKa_footer-container {
  background: linear-gradient(90deg, #161616 10% 75.68%, #161616 100%);
}

.ScEwKa_footer-text {
  color: #f2f2f2;
}
/*# sourceMappingURL=index.1a208b8b.css.map */
